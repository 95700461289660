import React, { useEffect, Suspense, useRef } from 'react';
import loadable from '@loadable/component';

import MainLayout from '../layouts/mainLayout';
import SEO from '../components/SEO';

import Loader from 'components/common/Loader';

import { deleteAllPetsList, deleteCheckoutState, isBrowser } from '../utils';
import Getstarted from 'components/Getstarted';

const Banner = loadable(() => import('components/Home/Banner'));
const HowSection = loadable(() => import('components/Home/HowSection'));
const FeaturesSection = loadable(() =>
  import('components/Home/FeaturesSection'),
);
const SectionBanner = loadable(() => import('components/Home/SectionBanner'));
const Testimonials = loadable(() => import('components/Home/Testimonials/Testimonials'));
const FeaturedSection = loadable(() =>
  import('components/common/FeaturedSection'),
);
const ChatFunctionality = loadable(() =>
  import('components/ChatFunctionality'),
);
const ActiveCampaignIntegration = loadable(() =>
  import('components/ActiveCampaignIntegration'),
);
const ABTestingIntegration = loadable(() =>
  import('components/ABTestingIntegration'),
);

const JoinFurFamily = loadable(() => import('components/JoinFurFamily'));

export default (props: any) => {
  useEffect(() => {
    deleteAllPetsList();
    deleteCheckoutState();
  }, []);
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        const container = document.querySelector('._close');
        if (container) {
          const specificElement = container.querySelector('i._close-icon');
          if (specificElement) {
            container.click();
          }
        }
      }
    };
    const handleOutsideClick = (event) => {
      const container = document.querySelector('._form-wrapper');
      const closeButton = document.querySelector('._close');
      if (container) {
        const specificElement = container.querySelector('form');
        if(specificElement){
          if (!specificElement.contains(event.target)) {
            closeButton?.click();
          }
        }
      }
    };

    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  
  return (
    <>
      <SEO
        title="Dog Food Delivery NZ Made Grain Free"
        meta={
          {
            name: `description`,
            content: 'Furbabies deserve the best. NZ made dog biscuits your dog will thrive on. Sustainably made, grain-free, and delivered to your door.',
          }
        }
        keywords={[
          `pet food new zealand`,
          `pet food nz`,
          `pet food`,
          `dog food`,
          `furbaby`,
          `dog biscuits nz`,
          `dog food online nz`,
          `grain free dog food nz`,
          `nz made dog food`,
          `puppy food nz`,
        ]}
        description="Our mission now is to improve the health of everyday pets by giving pet parents easier, more affordable access to natural, premium quality pet food that’s better for the planet."
      />
      {isBrowser() && (
        <Suspense fallback={<Loader />}>
          <ActiveCampaignIntegration />
          <ABTestingIntegration />
          <ChatFunctionality />
          <MainLayout>
            <Banner />
            <FeaturedSection />
            <HowSection />
            <FeaturesSection />
            <SectionBanner />
            <Testimonials />
            <JoinFurFamily page_id="home_page_footer" />
          </MainLayout>
        </Suspense>
      )}
    </>
  );
};
